import { Color, Text } from '../../constants'

export const styles = {
  btn: {
    cursor: 'pointer',
    display: 'inline-block',
    ...Text.SUBHEADER,
    lineHeight: 1.25,
    textDecoration: 'none',
    color: Color.WHITE,
    textAlign: 'center',
    transition: '0.2s',
    border: 0,
  },
  default: {
    padding: '12px 28px',
    minWidth: 150,
    borderRadius: '0.3rem',
    background: Color.PRIMARY,
    // backgroundImage: `linear-gradient(45deg, ${Color.PRIMARY} 30%,${
    //   Color.SECONDARY
    // } 90%)`,
    ':hover': {
      // backgroundImage: `linear-gradient(45deg, ${Color.SECONDARY} 30%,${
      //   Color.PRIMARY
      // } 90%)`,
      background: Color.SECONDARY,
    },
  },
  cta: {
    padding: '12px 28px',
    minWidth: 150,
    borderRadius: '0.3rem',
    background: Color.PRIMARY,
    // backgroundImage: `linear-gradient(45deg, ${Color.PRIMARY_CTA} 30%,${
    //   Color.PRIMARY_CTA
    // } 90%)`,
    ':hover': {
      // transform: 'scale(1.005)',
      background: Color.PRIMARY,
      opacity: 0.9,
      // backgroundImage: `linear-gradient(45deg, ${Color.PRIMARY_CTA} 30%,${
      //   Color.PRIMARY_CTA
      // } 90%)`,
    },
  },
  form: {
    padding: '10px 40px',
    borderRadius: '0 0.3rem 0.3rem 0',
    background: Color.PRIMARY,
    // backgroundImage: `linear-gradient(45deg, ${Color.PRIMARY} 30%,${
    //   Color.SECONDARY
    // })`,
    ':hover': {
      background: Color.PRIMARY,
      // backgroundImage: `linear-gradient(45deg, ${Color.SECONDARY} 30%,${
      //   Color.PRIMARY
      // } 90%)`,
    },
  },
  formCta: {
    padding: '10px 40px',
    borderRadius: '0 0.3rem 0.3rem 0',
    backgroundImage: `linear-gradient(45deg, ${Color.PRIMARY_CTA} 30%,${
      Color.SECONDARY_CTA
    } 90%)`,
    ':hover': {
      backgroundImage: `linear-gradient(45deg, ${Color.SECONDARY_CTA} 30%,${
        Color.PRIMARY_CTA
      } 90%)`,
    },
  },
}
