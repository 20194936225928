import React, { Component } from 'react'
import { css } from 'emotion'
import Button from './Button'
import { Color, Layout } from '../constants'
import PreviewCompatibleImage from './PreviewCompatibleImage'

class Hero extends Component {
  render() {
    const { type, home, title, text, button, image } = this.props

    const styles = {
      title: {
        maxWidth: 800,
        '@media (max-width: 706px)': {
          fontSize: Layout.MAIN_TITLE - 16,
        },
        lineHeight: 1,
        fontSize: Layout.MAIN_TITLE,
        fontWeight: 'bold',
        color: Color.WHITE,
        textAlign: 'center',
        paddingTop: Layout.EDGE_PADDING * 2,
        paddingBottom: Layout.EDGE_PADDING * 2,
        paddingLeft: Layout.EDGE_PADDING,
        paddingRight: Layout.EDGE_PADDING,
      },
      text: {
        '@media (max-width: 706px)': {
          fontSize: Layout.TITLE - 4,
        },
        fontWeight: '600',
        fontSize: Layout.TITLE,
        color: Color.WHITE,
        marginBottom: Layout.EDGE_PADDING * 1.5,
        textAlign: 'center',
      },
      container: {
        zIndex: 3,
        position: 'relative',
      },
      image: {
        paddingTop: 80,
        paddingBottom: 80,
        paddingLeft: Layout.EDGE_PADDING,
        paddingRight: Layout.EDGE_PADDING,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: home ? '80vh' : 400,
        maxHeight: home ? '95vh' : 640,
        borderRadius: 0,
      },
      contentContainer: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
      },
      wrapper: {
        position: 'absolute',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        '@media (max-width: 706px)': {
          top: 65 + 30,
        },
        top: 65 + 35,
      },
    }

    return (
      <div className={css(styles.container)}>
        <PreviewCompatibleImage imageInfo={image} style={styles.image} />
        <div className={css(styles.contentContainer)}>
          <div className={css(styles.wrapper)}>
            <h1 className={css(styles.title)}>{title}</h1>
            <p className={css(styles.text)}>{text}</p>
            {/* <a href={`tel:${button}`}>
              <Button btnType="cta" btnText={button} />
            </a> */}
          </div>
        </div>
        <Curve type={type} />
      </div>
    )
  }
}

class Curve extends Component {
  render() {
    const { type } = this.props
    const styles = {
      curve: {
        position: 'absolute',
        bottom: '-2px',
        left: 0,
      },
    }
    if (type === 'triple') {
      return (
        <svg
          className={css(styles.curve)}
          viewBox="0 0 1440 75"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 52.0788H717H1440V29.5078C1360.64 12.4677 1248.8 0 1143.5 0C792.63 0 674 25.7153 370.736 33.0956C188.866 37.5214 65.286 28.9109 0 7.26296V52.0788Z"
            fill="#D9DADB"
          />
          <path
            d="M0 63H717H1440V40.4632C1360.64 23.4489 1248.8 11 1143.5 11C792.63 11 674 36.6763 370.736 44.0455C188.866 48.4647 65.286 39.8672 0 18.252V63Z"
            fill="#F3F3F3"
          />
          <path
            d="M0 75H717.498H1441V52.4632C1361.58 35.4489 1249.67 23 1144.29 23C793.18 23 674.468 48.6763 370.993 56.0455C188.997 60.4647 65.3313 51.8672 0 30.252V75Z"
            fill="white"
          />
        </svg>
      )
    } else if (type === 'old-triple') {
      return (
        <svg
          className={css(styles.curve)}
          viewBox="0 0 1440 221"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 162H717H1440V46.75C1360.64 15.583 1268.3 0 1163 0C812.13 0 674 113.78 370.736 127.279C188.866 135.374 65.286 119.625 0 80.03V162Z"
            fill="#EBEBEB"
          />
          <path
            d="M0 190H717H1440V74.75C1360.64 43.583 1268.3 28 1163 28C812.13 28 674 141.78 370.736 155.279C188.866 163.374 65.286 147.625 0 108.03V190Z"
            fill="#F3F3F3"
          />
          <path
            d="M0 221H717H1440V105.75C1360.64 74.583 1268.3 59 1163 59C812.13 59 674 172.78 370.736 186.279C188.866 194.374 65.286 178.625 0 139.03V221Z"
            fill="white"
          />
        </svg>
      )
    } else if (type === 'swoop') {
      return (
        <svg
          className={css(styles.curve)}
          viewBox="0 0 1440 179"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 29.5C365 88.5 648.912 82.7987 839.045 75C1029.18 67.2013 1218 54 1440 0V135H0V29.5Z"
            fill="#EBEBEB"
          />
          <path
            d="M0 61C366 112 648.912 102.882 839.045 95.6031C1029.18 88.3242 1188 85.5 1440 29.5V151.603H0V61Z"
            fill="#F7F7F7"
          />
          <path
            d="M0 90.5C354.5 130.5 648.912 130.279 839.045 123C1029.18 115.721 1167 123 1440 61V179H0V90.5Z"
            fill="white"
          />
        </svg>
      )
    } else {
      return (
        <svg
          className={css(styles.curve)}
          viewBox="0 0 1440 115"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 115H1441V38.7697C1361.58 13.2911 1269.18 0.552246 1163.81 0.552246C812.693 0.552246 674.467 93.5658 370.993 104.601C188.997 111.219 65.3313 98.344 0 65.9756V115Z"
            fill="white"
          />
        </svg>
      )
    }
  }
}

export default Hero
