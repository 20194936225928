import React from 'react'
import { css } from 'emotion'
import { styles } from './styles'

const Button = props => {
  const { btnText, btnType, disabled, ...nativeProps } = props

  const btnClass = btnType ? styles[btnType] : styles.default

  return (
    <button
      className={css([styles.btn, btnClass])}
      disabled={disabled}
      {...nativeProps}
    >
      {btnText}
    </button>
  )
}

export default Button

Button.defaultProps = {
  btnText: 'Free Quote',
  type: 'submit',
  disabled: false,
}
