import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { css } from 'emotion'
import { Color, Layout, Text } from '../constants'
import PreviewCompatibleImage from './PreviewCompatibleImage'
import Button from './Button'

export class ServicesIntro extends Component {
  render() {
    const { intro, background } = this.props

    const styles = {
      container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        color: background === 'light' ? Color.DEFAULT : Color.WHITE,
        marginBottom: Layout.EDGE_PADDING * 2,
      },
      title: {
        ...Text.PAGE_HEADING,
        paddingBottom: Layout.OUTER_PADDING,
      },
      header: {
        ...Text.HEADER,
        paddingBottom: Layout.OUTER_PADDING,
        paddingLeft: Layout.EDGE_PADDING,
        paddingRight: Layout.EDGE_PADDING,
      },
      intro: {
        paddingBottom: Layout.OUTER_PADDING,
        maxWidth: 800,
        paddingLeft: Layout.OUTER_PADDING,
        paddingRight: Layout.OUTER_PADDING,
      },
      stepsContainer: {
        display: 'flex',
        '@media (max-width: 850px)': {
          flexDirection: 'column',
          alignItems: 'center',
        },
        flexDirection: 'row',
        marginBottom: Layout.OUTER_PADDING * 3,
      },
      servicesTitle: {
        textAlign: 'center',
        ...Text.TITLE,
        paddingBottom: 16,
      },
      details: {
        display: 'flex',
        flexDirection: 'column',
        maxWidth: 800,
        margin: Layout.OUTER_PADDING,
      },
      steps: {
        display: 'flex',
        flexDirection: 'column',
        maxWidth: 375,
      },
      stepHeader: {
        ...Text.HEADER,
        paddingBottom: Layout.INNER_PADDING,
      },
      stepDescription: {
        paddingBottom: 12,
      },
      image: {
        width: 300,
        height: 375,
        backgroundColor: Color.BLACK,
        borderRadius: 4,
        margin: Layout.OUTER_PADDING,
      },
    }

    return (
      <section className={css(styles.container)}>
        <h2 className={css(styles.title)}>{intro.title}</h2>
        {/* <div className={css(styles.header)}>{intro.sub_header}</div> */}
        <p className={css(styles.intro)}>{intro.description}</p>
        <div className={css(styles.stepsContainer)}>
          <PreviewCompatibleImage
            imageInfo={intro.image}
            style={styles.image}
          />
          <div className={css(styles.details)}>
            <h4 className={css(styles.servicesTitle)}>{intro.processTitle}</h4>
            <div className={css(styles.steps)}>
              {intro.steps &&
                intro.steps.map((item, i) => (
                  <div key={`${item.title}-${i}`}>
                    <h5 className={css(styles.stepHeader)}>{item.title}</h5>
                    <p className={css(styles.stepDescription)}>
                      {item.description}
                    </p>
                  </div>
                ))}
            </div>
          </div>
        </div>
        <a href={intro.button && intro.button.action}>
          <Button
            btnType={intro.button && intro.button.type}
            btnText={intro.button && intro.button.text}
          />
        </a>
      </section>
    )
  }
}

export const query = graphql`
  fragment ServicesIntroFragment on Query {
    servicesIntroData: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "service-page" } } }
    ) {
      edges {
        node {
          id
          frontmatter {
            intro {
              title
              sub_header
              description
              image {
                childImageSharp {
                  fluid(maxWidth: 2048, quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              processTitle
              button {
                action
                type
                text
              }
              steps {
                title
                description
              }
            }
          }
        }
      }
    }
  }
`
