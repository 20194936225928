import React from 'react'
import { css } from 'emotion'
import { Layout as _Layout, Color } from '../constants'

export const CurveSection = ({ padding, render }) => {
  const styles = {
    darkSection: {
      zIndex: 2,
      position: 'relative',
      // background: `linear-gradient(210.34deg, #1F2123 3.24%, #656769 105.67%)`,
      // background: `linear-gradient(213.83deg, #656769 33.6%, #949597 57.33%)`,
      background: `linear-gradient(219.3deg, ${Color.DARK_GRAY} 3.25%, ${
        Color.MEDIUM_GRAY
      } 165.7%)`,
      paddingBottom: _Layout.OUTER_PADDING * padding,
      paddingTop: _Layout.OUTER_PADDING * padding,
    },
    topCurve: {
      position: 'absolute',
      top: '-2px',
      left: 0,
    },
    bottomCurve: {
      position: 'absolute',
      bottom: '-2px',
      left: 0,
    },
  }

  return (
    <section style={styles.darkSection}>
      <svg
        className={css(styles.topCurve)}
        viewBox="0 0 1440 114"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1441 0L0 0V75.9138C79.42 101.286 171.817 113.972 277.192 113.972C628.305 113.972 766.531 21.3454 1070.01 10.356C1252 3.76598 1375.67 16.5871 1441 48.8209V0Z"
          fill="white"
        />
      </svg>

      {render && render()}

      <svg
        className={css(styles.bottomCurve)}
        viewBox="0 0 1440 115"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 115H1441V38.7697C1361.58 13.2911 1269.18 0.552246 1163.81 0.552246C812.693 0.552246 674.467 93.5658 370.993 104.601C188.997 111.219 65.3313 98.344 0 65.9756V115Z"
          fill="white"
        />
      </svg>
    </section>
  )
}
